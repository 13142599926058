<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>503 Under Maintenance</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">503 Under Maintenance</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<div class="error-area ptb-100">
    <div class="error-page">
        <img  style="height: 250px;" src="assets/images/error503.png" alt="error">
        <h3>Oops! Page Under Maintenance</h3>
        <p>The page you were looking is under maintenance.</p>
        <a routerLink="/" class="box-btn">Return To Home Page</a>
    </div>
</div>